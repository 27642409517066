import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { AdsGETApiAuth } from "../../../Constant/api";
import { slugs } from "../../../Constant/slugs";
const columns = [
  {
    id: 1,
    name: "id",
    selector: (row) => row.meta.user_id,
    sortable: true,
    reorder: true,
    width:"100px"
  },
  {
    id: 31,
    name: "Title",
    selector: (row) => row.title.rendered,
    sortable: true,
    reorder: true
  },
  {
    id: 312,
    name: "Phone",
    selector: (row) => row.meta.user_phone_number,
    sortable: true,
    reorder: true
  },
  {
    id: 313,
    name: "Email",
    selector: (row) => row.meta.user_email,
    sortable: true,
    reorder: true
  },
  {
    id: 32,
    name: "role",
    selector: (row) => row.meta.user_role,
    sortable: true,
    reorder: true
  },
  {
    id: 3,
    name: "View",
    // right: true,
    cell: (row) => <Link to={`${slugs.student_details}${row.slug}`}>View</Link>,
  }
];

export default function AllStudents() {
  const [Projects1, setProjects1] = React.useState([])
  const [CustomersList, setCustomersList] = React.useState([])
  const [Reload, setReload] = React.useState(false)
  const [SubscriberTotal, setSubscriberTotal] = React.useState(0)
  const [StudentTotal, setStudentTotal] = React.useState(0)
  const [UnverifiedTotal, setVerifiedTotal] = React.useState(0)
  const [AdminTotal, setAdminTotal] = React.useState(0)
  const [AllTotal, setAllTotal] = React.useState(0)
  const [serachName, setSearchName] = React.useState("")
  const [serachEmail, setSearchEmail] = React.useState("")
  const [serachPhone, setSearchPhone] = React.useState("")
  const GetTotal = (Role, setDataLength, all) => {
    setReload(true)
    setProjects1([])
    const allT = !all ? {
      "filter[meta_query][1][key]": "user_role",
      "filter[meta_query][1][value][0]": `${Role}`,
      "filter[meta_query][1][compare]": "=",
      per_page: 1
    } : { per_page: 1 }
    AdsGETApiAuth(allT, slugs.db_slug_user_data)
      .then((res) => {
        setDataLength(res.headers["x-wp-total"])
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  const GetAllProjects = (params) => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth(params, slugs.db_slug_user_data)
      .then((res) => {
        setProjects1(res.data)
        setCustomersList(res.data)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    GetAllProjects({ per_page: 4999 })
    GetTotal("subscriber", setSubscriberTotal, false)
    GetTotal("student", setStudentTotal, false)
    GetTotal("author", setAdminTotal, false)
    GetTotal("all", setAllTotal, true)

  }, [])

  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>All Students</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / All Students
        </div>
      </div>
      <a href={slugs.new_student}>
        <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
          onClick={() => {

          }}
        >Add New Student</div>
      </a>
      <div className="row">
        <div className="col-md-6">
          <div style={{ display: "inline", cursor: "pointer" }}>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setProjects1(CustomersList)
              }}
            >
              All ({AllTotal}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setProjects1(CustomersList.filter(a => a.meta.user_role == "subscriber"))
              }}
            >
              Subscribers ({SubscriberTotal}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setProjects1(CustomersList.filter(a => a.meta.user_role == "student"))
              }}
            >
              Students ({StudentTotal}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setProjects1(CustomersList.filter(a => a.meta.user_role == "teacher"))
              }}
            >
              Teacher ({CustomersList.filter(a => a.meta.user_role == "teacher").length}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setProjects1(CustomersList.filter(a => a.meta.user_role == "author"))
              }}
            >
              Admin ({AdminTotal})
            </div>
          </div>
        </div>
        <div className="col-md-6 row">
          <div className="col-4" />
          <input className="col-2" style={{ margin: "1px 2px", padding: "3px 2px" }}
            value={serachName} placeholder="Name"
            onChange={(event) => {
              setSearchName(event.target.value)
              setProjects1(CustomersList.filter(a => a.meta.user_name.includes(event.target.value)))
            }}
          />
          <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
            value={serachEmail} placeholder="Email"
            onChange={(event) => {
              setSearchEmail(event.target.value)
              setProjects1(CustomersList.filter(a => a.meta.user_email.includes(event.target.value)))
            }}
          />
          <input className="col-2" style={{ margin: "1px 2px", padding: "3px 2px" }}
            value={serachPhone} placeholder="Phone"
            onChange={(event) => {
              setSearchPhone(event.target.value)
              setProjects1(CustomersList.filter(a => a.meta.user_phone_number.includes(event.target.value)))
            }}
          />
        </div>
      </div>

      <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px" }}>
        <DataTable
          paginationPerPage={100}
          paginationRowsPerPageOptions={[10, 50, 100, 1000]}
          columns={columns}
          data={Projects1}
          defaultSortFieldId={1}
          defaultSortAsc={false}
          striped
          // sortIcon={<SortIcon />}
          pagination
          selectableRows
        />
      </div>
      {Reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}