import React from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsPOSTApi, AdsPUTApi } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";
import { AlertComponent } from "../../../AlertComponent";
import AudioUpload from "../../../AudioUpload";
import { CustomButton } from "../../../CustomButtom";
import FileUpload from "../../../FileUpload";
import AudioUpload2 from "../../Book/Components/AudioUpload2";

export default function FormComponent(props) {
  const { FetchData } = props;
  const history = useHistory()
  const [ID, setID] = React.useState(FetchData.id)

  const [Title, setTitle] = React.useState(FetchData.title.rendered)
  const [URL, setURL] = React.useState(FetchData.meta.audio_url)
  const [Time, setTime] = React.useState(FetchData.meta.audio_time)
  const [FileSize, setFileSize] = React.useState(FetchData.meta.audio_file_size)
  const [Category, setCategory] = React.useState(FetchData.meta.category)
  const [CourseContent, setCourseContent] = React.useState(JSON.parse(FetchData.meta.course_content))
  const [CourseContentEdit, setCourseContentEdit] = React.useState(false)
  const [CourseEditData, setCourseEditData] = React.useState({})
  const [CourseEditKey, setCourseEditKey] = React.useState("")

  const [reload, setReload] = React.useState(false)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")

  const EditInfo = () => {
    setReload(true)
    const data = {
      "title": Title,
      "status": "publish",
      "meta": {
        "audio_title": `${Title}`,
        "audio_url": `${URL}`,
        "audio_time": `${Time}`,
        "audio_file_size": `${FileSize}`,
        "category": `${Category}`,
        "course_content": JSON.stringify(CourseContent)
      }
    }

    if (ID === "")
      AdsPOSTApi(data, slugs.db_slug_audios)
        .then((res) => {
          setReload(false)
          setID(res.data.id)
          history.push(`${slugs.audio_details}${res.data.slug}`)
        }).catch(err => {
          setReload(false)
          console.log(err)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
    else
      AdsPUTApi(data, slugs.db_slug_audios, ID)
        .then((res) => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(`${Title} edited Successfully`)
        }).catch(err => {
          console.log(err)
          console.warn(err.response.data)
          console.log("Response Status: hello11", err.response.status);
          console.log("Response Headers:", err.response.headers);
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
  }

  return (
    <div>
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Audio Information</center></h4>
          <table>
            <tbody>
              <tr>
                <td className="col-3">Audio Title</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Title} placeholder="Title"
                    onChange={(event) => { setTitle(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Category</td>
                <td className="col-6">
                  <CustomButton setData={setCategory} Data={Category} DataValue={"PBT"} />
                  <CustomButton setData={setCategory} Data={Category} DataValue={"TextBook"} />
                </td>
              </tr>
              {Category == "PBT" &&
                <>
                  <tr>
                    <td className="col-3">Audio Duration</td>
                    <td className="col-6">
                      <input className="input-common"
                        value={Time} placeholder="Audio Duration"
                        onChange={(event) => { setTime(event.target.value) }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="col-3">Audio File Size</td>
                    <td className="col-6">
                      <input className="input-common"
                        value={FileSize} placeholder="Audio File Size"
                        onChange={(event) => { setFileSize(event.target.value) }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="col-3">Audio URL</td>
                    <td className="col-6">
                      <AudioUpload
                        ImageUrl={URL}
                        setImageUrl={setURL}
                      />
                    </td>
                  </tr>
                </>
              }
            </tbody>
          </table>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  EditInfo()
                }}
              >
                Update Audio
              </div>
            </div>
          </div>
        </div>
      </div>
      {Category !== "PBT" &&
        <div className="row">
          <div className="col-1"></div>
          <div className="col-10 form-view mb-3">
            <h4><center>Chapter Wise Audios</center></h4>
            <div>
              <div className="row center m-2">
                <div className=" col-3" style={{ padding: "0 10px" }}>
                  <div className="sign-in-button-4"
                    onClick={() => {
                      setCourseEditData({
                        "chapter_number": `${Object.keys(CourseContent).length + 1}`,
                        "title": "",
                        "description": "",
                        "is_preview": "",
                        "chapter_type": "Video",
                        "video_link": "",
                        "youtube_id": "",
                        "content": "",
                        "time": "",
                        "pdf_url": "",
                        "pdf_list": "[]",
                        "audio_list": "[]",
                      })
                      setCourseEditKey(`item-${Object.keys(CourseContent).length}`)

                      setCourseContentEdit(true)
                    }}
                  >
                    Add Chapter
                  </div>
                </div>
              </div>
              <div>
                <div className="row m-4">
                  <div>
                    <div className="row">
                      {Object.values(CourseContent).map((item, index) => {
                        return (
                          <div key={index} className="col-6">
                            <div
                              style={{
                                margin: "5px", padding: "5px 10px",
                                border: "2px solid black",
                                borderRadius: "10px",
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                setCourseEditData(item)
                                setCourseEditKey(Object.keys(CourseContent)[index])
                                setCourseContentEdit(true)

                              }}
                            >
                              <div style={{ fontWeight: "bold", }}>
                                {item.title}
                              </div>

                              <div style={{}}>
                                {item.description}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      }
      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {
        AlertShow &&
        <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
      }
      {
        CourseContentEdit &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-2 col-1"></div>
            <div className="col-md-8 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setCourseContentEdit(false)
                    setCourseEditData([])
                    setCourseEditKey("")
                  }}
                >X</div>

                <CourseUploadComp
                  oneChapterData={CourseEditData} setCourseEditData={setCourseEditData}
                  keyValue={CourseEditKey} setCourseEditKey={setCourseEditKey}
                  CourseContent={CourseContent} setCourseContent={setCourseContent}
                  setCourseContentEdit={setCourseContentEdit}
                  CourseType={"Book"}
                />
              </div>
            </div>
          </div>
        </section>
      }
    </div>
  )

}



const CourseUploadComp = ({
  oneChapterData, setCourseEditData,
  keyValue, setCourseEditKey, setCourseContentEdit,
  CourseContent, setCourseContent,
  CourseType
}) => {
  const [ChapterNumber, setChapterNumber] = React.useState(oneChapterData.chapter_number)
  const [Title, setTitle] = React.useState(oneChapterData.title)
  const [Description, setDescrition] = React.useState(oneChapterData.description)
  const [Time, setTime] = React.useState(oneChapterData.time)
  const [IsPreview, setIsPreview] = React.useState(oneChapterData.is_preview)
  const [VideoLink, setVideoLink] = React.useState(oneChapterData.video_link)
  const [YoutubeID, setYoutubeID] = React.useState(oneChapterData.youtube_id)
  const [ChapterType, setChapterType] = React.useState("PDF")
  const [Content, setContent] = React.useState(oneChapterData.content)
  const [PDFURL, setPDFURL] = React.useState(oneChapterData.pdf_url)
  const [PdfList, setPdfList] = React.useState(JSON.parse(oneChapterData.pdf_list))
  const [AudioList, setAudioList] = React.useState(JSON.parse(oneChapterData.audio_list))
  const [AudioURL, setAudioURL] = React.useState("")
  const [PageNumber, setPageNumber] = React.useState("")
  const [TitleAudio, setTitleAudio] = React.useState("")

  function YouTubeGetID(url) {
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined)
      setYoutubeID(url[2].split(/[^0-9a-z_\-]/i)[0])
    // console.log(url[2].split(/[^0-9a-z_\-]/i)[0])
    return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
  }
  return (
    <div>
      <div style={{ maxHeight: "60vh", overflowY: "scroll" }}>
        <table>
          <tbody>
            <tr>
              <td className="col-3">Chapter Number</td>
              <td className="col-6">
                {/* {ChapterNumber} */}
                <input className="input-common-4"
                  value={ChapterNumber} placeholder="Chapter Number"
                  onChange={(event) => { setChapterNumber(event.target.value) }}
                />
              </td>
            </tr>
            <tr>
              <td className="col-3">Chapter Title</td>
              <td className="col-6">
                <input className="input-common-4"
                  value={Title} placeholder="Chapter Title"
                  onChange={(event) => { setTitle(event.target.value) }}
                />
              </td>
            </tr>
            <tr>
              <td className="col-3">Chapter Description</td>
              <td className="col-6">
                <input className="input-common-4"
                  value={Description} placeholder="Chapter Description"
                  onChange={(event) => { setDescrition(event.target.value) }}
                />
              </td>
            </tr>


            <tr>
              <td className="col-3">Audio Files</td>
              <td className="col-6">

                <table>
                  <tbody>
                    {Object.values(AudioList).map((item, index) => (
                      <tr key={index} style={{ cursor: "pointer", padding: "5px 0" }}>
                        <td>
                          {index + 1}
                        </td>
                        <td className="col-6">
                          <AudioUpload2
                            ImageUrl={AudioURL}
                            setImageUrl={setAudioURL}
                            PDfGroup={AudioList}
                            setPDFGroup={setAudioList}
                            item={item}
                            index={index}
                            newUpload={false}
                          />
                        </td>
                        <td>
                          <div style={{ width: "100%" }}>
                            <input className="input-common-half" style={{ width: "100%", marginTop: 5 }}
                              value={item.title} placeholder="Pg"
                              onChange={(event) => {
                                var pdf = AudioList;
                                pdf = {
                                  ...pdf,
                                  [`item-${index}`]: {
                                    "title": `${event.target.value}`,
                                    "page_number": `${item.page_number}`,
                                    "audio_url": `${item.audio_url}`,
                                  }
                                }
                                setAudioList(pdf)
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <div style={{ width: "100%" }}>
                            <input className="input-common-half" style={{ width: "100%", marginTop: 5 }}
                              value={item.page_number} placeholder="Pg"
                              onChange={(event) => {
                                var pdf = AudioList;
                                pdf = {
                                  ...pdf,
                                  [`item-${index}`]: {
                                    "title": `${item.title}`,
                                    "page_number": `${event.target.value}`,
                                    "audio_url": `${item.audio_url}`,
                                  }
                                }
                                setAudioList(pdf)
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                    <tr style={{ cursor: "pointer", padding: "5px 0" }}>
                      <td>
                        {Object.values(AudioList).length + 1}
                      </td>
                      {/* {AudioURL} */}
                      <td className="col-3">
                        <AudioUpload2
                          ImageUrl={AudioURL}
                          setImageUrl={setAudioURL}
                          PDfGroup={AudioList}
                          setPDFGroup={setAudioList}
                          item={{
                            "title": `${Title}`,
                            "page_number": `${PageNumber}`,
                            "audio_url": `${AudioURL}`,
                          }}
                          index={Object.keys(AudioList).length}
                          newUpload={true}
                        />
                      </td>
                      <td className="col-3">
                        <div style={{ width: "100%" }}>
                          <input className="input-common-half" style={{ width: "100%", marginTop: 5 }}
                            value={TitleAudio} placeholder="Titile"
                            onChange={(event) => {
                              setTitleAudio(event.target.value)
                            }}
                          />
                        </div>
                      </td>
                      <td className="col-3">
                        <div style={{ width: "100%" }}>
                          <input className="input-common-half" style={{ width: "100%", marginTop: 5 }}
                            value={PageNumber} placeholder="Pg"
                            onChange={(event) => {
                              setPageNumber(event.target.value)
                            }}
                          />
                        </div>
                      </td>
                      <td onClick={() => {
                        // setNumberofChapters(event.target.value)
                        var pdf = AudioList;
                        pdf = {
                          ...pdf,
                          [`item-${Object.keys(AudioList).length}`]: {
                            "title": `${TitleAudio}`,
                            "page_number": `${PageNumber}`,
                            "audio_url": `${AudioURL}`,
                          }
                        }
                        setAudioList(pdf)
                        setTitleAudio("")
                        setPageNumber("")
                        setAudioURL("")
                      }}>
                        Add
                      </td>
                    </tr>

                  </tbody>
                </table>

                {/* {Object.values(AudioList).map((item, index) => (
                  <div key={index}>
                    <div style={{ display: "inline" }}>
                      <div style={{ display: "inline-block" }}>
                        {index + 1}.
                      </div>
                      <div style={{ display: "inline-block" }}>
                        <FileUploadChapters
                          PDfGroup={AudioList}
                          setPDFGroup={setAudioList}
                          ThisPDF={item}
                          index={index}
                          type={"audio"}
                        />
                      </div>
                    </div>
                  </div>
                ))}
                <div style={{ display: "inline" }}>
                  <div style={{ display: "inline-block" }}>
                    {Object.values(AudioList).length + 1}.
                  </div>
                  <div style={{ display: "inline-block" }}>
                    <FileUploadChapters
                      PDfGroup={AudioList}
                      setPDFGroup={setAudioList}
                      ThisPDF={""}
                      index={Object.values(AudioList).length}
                      type={"audio"}
                    />
                  </div>
                </div> */}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <center>
        <div style={{ width: "30%" }} className="sign-in-button-1"
          onClick={() => {
            setCourseContent({
              ...CourseContent,
              [keyValue]: {
                "chapter_number": `${ChapterNumber}`,
                "title": `${Title}`,
                "time": `${Time}`,
                "description": `${Description}`,
                "is_preview": `${IsPreview}`,
                "chapter_type": `${ChapterType}`,
                "video_link": `${VideoLink}`,
                "youtube_id": `${YoutubeID}`,
                "content": `${Content}`,
                "pdf_url": `${PDFURL}`,
                "pdf_list": `${JSON.stringify(PdfList)}`,
                "audio_list": `${JSON.stringify(AudioList)}`,
              }
            })
            setCourseEditKey(``)
            setCourseContentEdit(false)
          }}
        >Done</div>
      </center>
    </div >
  )
}
