import React from "react";
import { useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsGETApiAuth, AdsPOSTApi, AdsPUTApi } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";
import { AlertComponent } from "../../../AlertComponent";
import AudioUpload from "../../../AudioUpload";
import { CustomButton } from "../../../CustomButtom";
import FileUpload from "../../../FileUpload";
import AudioUpload2 from "../../Book/Components/AudioUpload2";

export default function FormComponent(props) {
  const { FetchData } = props;
  const history = useHistory()
  const [ID, setID] = React.useState(FetchData.id)

  const [Title, setTitle] = React.useState(FetchData.title.rendered)
  const [ValidityTime, setValidityTime] = React.useState(FetchData.meta.validity_time)
  const [ValidityNumber, setValidityNumber] = React.useState(FetchData.meta.validity_number)
  const [ValidityUserID, setValidityUserID] = React.useState(FetchData.meta.validity_user_id)
  const [ValidityUserName, setValidityUserName] = React.useState(FetchData.meta.validity_user_name)
  const [ValidityID, setValidityID] = React.useState(FetchData.meta.validity_id)
  const [ValidityName, setValidityName] = React.useState(FetchData.meta.validity_name)
  const [Category, setCategory] = React.useState(FetchData.meta.category)

  const [reload, setReload] = React.useState(false)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")

  const EditInfo = () => {
    setReload(true)
    const data = {
      "title": Title,
      "status": "publish",
      "meta": {
        "token": `${Title}`,
        "validity_time": `${ValidityTime}`,
        "validity_number": `${ValidityNumber}`,
        "validity_user_id": `${ValidityUserID}`,
        "validity_user_name": `${ValidityUserName}`,
        "validity_id": `${ValidityID}`,
        "validity_name": `${ValidityName}`,
        "category": `${Category}`,
      }
    }

    if (ID === "")
      AdsPOSTApi(data, slugs.db_slug_audios_token)
        .then((res) => {
          setReload(false)
          setID(res.data.id)
          history.push(`${slugs.audio_token_details}${res.data.slug}`)
        }).catch(err => {
          setReload(false)
          console.log(err)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
    else
      AdsPUTApi(data, slugs.db_slug_audios_token, ID)
        .then((res) => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(`${Title} edited Successfully`)
        }).catch(err => {
          console.log(err)
          console.warn(err.response.data)
          console.log("Response Status: hello11", err.response.status);
          console.log("Response Headers:", err.response.headers);
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
  }
  const RandomVar = () => {
    var chars = "0123456789abcdefghijklmnopqrstuvwxyz";
    var passwordLength = 3;
    var password = "";
    for (let l = 0; l <= passwordLength; l++) {
      var randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber + 1);
    }
    setTitle(password)
    // return password;
  }

  useEffect(() => {
    FetchExamList(slugs.db_slug_audios, setAllAudioList, setAllAudioList1)
    FetchExamList(slugs.db_slug_buy_exam, setAllExamList, setAllExamList1)
    FetchExamList(slugs.db_slug_user_data, setAllUserList, setAllUserList1)
  }, [])
  const [AllAudioList, setAllAudioList] = React.useState([]);
  const [AllAudioList1, setAllAudioList1] = React.useState([]);
  const [AllUserList, setAllUserList] = React.useState([]);
  const [AllUserList1, setAllUserList1] = React.useState([]);
  const [AllExamList, setAllExamList] = React.useState([]);
  const [AllExamList1, setAllExamList1] = React.useState([]);
  const [examModal, setExamModal] = React.useState(false);
  const [userModal, setUserModal] = React.useState(false);
  const [audioModal, setAudioModal] = React.useState(false);

  const FetchExamList = (slug, setData, setData1) => {
    AdsGETApiAuth(
      { per_page: 30000, _fields: "id,slug,title,meta.user_id" },
      slug
    )
      .then((res) => {
        setData(res.data)
        setData1(res.data)
      })
      .catch(e => {
        console.log(e)
      })
  }

  const [BatchExamDeleteIndex, setBatchExamIndex] = React.useState("")
  const [BatchExamDeleteModal, setBatchExamDeleteModal] = React.useState(false)
  const [batchModal, setBatchModal] = React.useState(false)
  const [serachName, setSearchName] = React.useState("")

  return (
    <div>
      <div className="row">
        <div className="col-1"></div>
        <div className="col-10 form-view mb-3">
          <h4><center>Audio Information</center></h4>
          <table>
            <tbody>
              <tr>
                <td className="col-3">Token</td>
                <td className="col-6">
                  <div className="input-common">
                    <input style={{ width: "90%", padding: 0, border: "none" }}
                      value={Title} placeholder="Token"
                      onChange={(event) => { setTitle(event.target.value) }}
                    />
                    <span onClick={() => {
                      RandomVar()
                    }}>
                      <img src="https://api.helloepskorea.com/wp-content/uploads/2024/01/images.jpeg"
                        style={{ width: "20px", height: "20px" }}
                      />
                    </span>
                  </div>

                </td>
              </tr>
              <tr>
                <td className="col-3">Category</td>
                <td className="col-6">
                  <CustomButton setData={setCategory} Data={Category} DataValue={"Exam"} />
                  <CustomButton setData={setCategory} Data={Category} DataValue={"Audio"} />
                </td>
              </tr>
              <tr>
                <td className="col-3">Token Validity</td>
                <td className="col-6">
                  <div className=" row">
                    <div className="col-sm-8">
                      <input className="input-common" style={{ width: "100%" }}
                        type="date"
                        value={ValidityTime} placeholder="Validity Duration"
                        onChange={(event) => { setValidityTime(event.target.value) }}
                      />
                    </div>
                    <div className="col-sm-4" style={{ cursor: "pointer", margin: "10px 0", textAlign: "center" }}
                      onClick={() => {
                        var date = new Date()
                        setValidityTime(JSON.stringify(date).slice(1, 11))
                      }}
                    >
                      &nbsp;&nbsp;End Token
                    </div>
                  </div>
                </td>
              </tr>
              {/* <tr>
                <td className="col-3">Token Validity Number of Times</td>
                <td className="col-6">
                  <input className="input-common"
                    value={ValidityNumber} placeholder="Token Validity Number of Times"
                    onChange={(event) => { setValidityNumber(event.target.value) }}
                  />
                </td>
              </tr> */}
              <tr>
                <td className="col-3">Validity For (user)</td>
                <td className="col-6">
                  <div className=" row" style={{ cursor: "pointer" }}>
                    <div className="col-sm-8">
                      <div className="input-common" style={{ margin: "10px 0" }}
                        onClick={() => {
                          setUserModal(!userModal)
                        }}
                      >
                        {ValidityUserName}&nbsp;
                      </div>
                    </div>
                    <div className="col-sm-4" style={{ margin: "5px 0 0", textAlign: "center" }}
                      onClick={() => {
                        setValidityUserID("")
                        setValidityUserName("")
                      }}
                    >
                      Open for all<br />Users
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="col-3">Validity For (content)</td>
                <td className="col-6">
                  <div className=" row" style={{ cursor: "pointer" }}>
                    <div className="col-sm-8">
                      <div className="input-common" style={{ margin: "10px 0" }}
                        onClick={() => {
                          if (Category == "Exam")
                            setExamModal(!examModal)
                          else
                            setAudioModal(!audioModal)

                        }}
                      >
                        {ValidityName}&nbsp;
                      </div>
                    </div>
                    <div className="col-sm-4" style={{ margin: "5px 0 0", textAlign: "center" }}
                      onClick={() => {
                        setValidityID("")
                        setValidityName("")
                      }}
                    >
                      Open for all<br/>Content
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  EditInfo()
                }}
              >
                Update Token
              </div>
            </div>
          </div>
        </div>
      </div>
      {examModal &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ maxHeight: "400px", margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setExamModal(!examModal)
                  }}
                >X</div>
                <div className="row" style={{ cursor: "pointer", border: "1px solid #999" }}>

                  <div className="row" style={{ width: "100%", justifyContent: "space-evenly", cursor: "pointer", border: "1px solid #999" }} >
                    <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
                      value={serachName} placeholder="Name"
                      onChange={(event) => {
                        setSearchName(event.target.value)
                        setAllExamList1(AllExamList.filter(a => a.title.rendered.toLowerCase().includes(event.target.value.toLowerCase())))
                      }}
                    />
                  </div>
                </div>
                <table>
                  <tbody>
                    {AllExamList1.map((item, index) => (
                      <tr className="row bg-white shadow" key={index}
                        style={{ overflowX: "hidden", cursor: "pointer", border: "1px solid #999" }}
                        onClick={() => {
                          setValidityID(`${item.id}`)
                          setValidityName(item.title.rendered)
                          setExamModal(!examModal)
                        }}
                      >
                        <td className="col-1">
                          {ValidityID !== item.id ?
                            <i className="fa fa-square-o" />
                            :
                            <i className="fa fa-check-square-o" />
                          }
                        </td>
                        <td className="col-2" >
                          {item.id}
                        </td>
                        <td className="col-5">
                          {item.title.rendered}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      }
      {userModal &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ maxHeight: "400px", margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setUserModal(!userModal)
                  }}
                >X</div>
                <div className="row" style={{ cursor: "pointer", border: "1px solid #999" }}>

                  <div className="row" style={{ width: "100%", justifyContent: "space-evenly", cursor: "pointer", border: "1px solid #999" }} >
                    <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
                      value={serachName} placeholder="Name"
                      onChange={(event) => {
                        setSearchName(event.target.value)
                        setAllUserList1(AllUserList.filter(a => a.title.rendered.toLowerCase().includes(event.target.value.toLowerCase())))
                      }}
                    />
                  </div>
                </div>
                <table>
                  <tbody>
                    {AllUserList1.map((item, index) => (
                      <tr className="row bg-white shadow" key={index}
                        style={{ overflowX: "hidden", cursor: "pointer", border: "1px solid #999" }}
                        onClick={() => {
                          setValidityUserID(`${item.meta.user_id}`)
                          setValidityUserName(item.title.rendered)
                          setUserModal(!userModal)
                        }}
                      >
                        <td className="col-1">
                          {ValidityUserID !== item.id ?
                            <i className="fa fa-square-o" />
                            :
                            <i className="fa fa-check-square-o" />
                          }
                        </td>
                        <td className="col-2" >
                          {item.meta.user_id}
                          {/* {item.id} */}
                        </td>
                        <td className="col-5">
                          {item.title.rendered}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      }

      {audioModal &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ maxHeight: "400px", margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setAudioModal(!audioModal)
                  }}
                >X</div>
                <div className="row" style={{ cursor: "pointer", border: "1px solid #999" }}>

                  <div className="row" style={{ width: "100%", justifyContent: "space-evenly", cursor: "pointer", border: "1px solid #999" }} >
                    <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
                      value={serachName} placeholder="Name"
                      onChange={(event) => {
                        setSearchName(event.target.value)
                        setAllAudioList1(AllAudioList.filter(a => a.title.rendered.toLowerCase().includes(event.target.value.toLowerCase())))
                      }}
                    />
                  </div>
                </div>
                <table>
                  <tbody>
                    {AllAudioList1.map((item, index) => (
                      <tr className="row bg-white shadow" key={index}
                        style={{ overflowX: "hidden", cursor: "pointer", border: "1px solid #999" }}
                        onClick={() => {
                          setValidityID(`${item.id}`)
                          setValidityName(item.title.rendered)
                          setAudioModal(!audioModal)
                        }}
                      >
                        <td className="col-1">
                          {ValidityUserID !== item.id ?
                            <i className="fa fa-square-o" />
                            :
                            <i className="fa fa-check-square-o" />
                          }
                        </td>
                        <td className="col-2" >
                          {item.id}
                        </td>
                        <td className="col-5">
                          {item.title.rendered}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      }

      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {
        AlertShow &&
        <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
      }
    </div>
  )

}

